import {
  createStore
} from "vuex";
import {
  getVideos
} from "@/api";

export default createStore({
  state: {
    browser: {
      name: "",
      version: "",
    },
    videosLoaded: false,
    videos: [],
    instagramTopThree: []
  },
  mutations: {
    setBrowser(state, data) {
      // console.log(data)
      state.browser = data
    },
    setVideos(state, data) {
      state.videos = data
    },
    setVideosLoaded(state) {
      state.videosLoaded = true
    },
    setTopThree(state, data) {
      state.instagramTopThree = data
    },
  },
  actions: {
    addOneVoteToVideo(context, videoId){
      let video = this.state.videos.find(vid => vid.id === videoId)
      video.tickets += 1
    },
    updateVideos(context, queries) {
      console.log("getting videos")
      getVideos(queries).then(response => {
        console.log('get videos')
        let storageBaseUrl = process.env.VUE_APP_FILE_BASE_URL
        let videos = response.map(vid => {
          return {
            avatar: storageBaseUrl + vid.owner_pic_url,
            image: storageBaseUrl + vid.thumbnails_url,
            name: vid.caption,
            video: storageBaseUrl + vid.video_url,
            tickets: vid.votes,
            id: vid.id,
            dateTs: Date.parse(vid.source_upload_date),
            date: vid.source_upload_date
          }
        })
        context.commit('setVideos', videos)
        context.commit('setVideosLoaded')
        let topVideos = this.getters.getTopVideos(3)
        context.commit('setTopThree', topVideos)
      })
    },
    sortVideos(context, sortBy) {
      this.state.videos.sort((vid1, vid2) => {
        if (sortBy === "ASC") return vid2.dateTs - vid1.dateTs
        if (sortBy === "DESC") return vid1.dateTs - vid2.dateTs
        return vid2.tickets - vid1.tickets
      })
    }
  },
  getters: {
    getTopVideos: (state) => (n) => {
      n = n ? 3 : n
      let sortedVideos = state.videos.map(x => x)
      sortedVideos.sort((vid1, vid2) => {
        return vid2.tickets - vid1.tickets
      })
      let topVids = sortedVideos.splice(0, n)
      return topVids
    }
  },
  modules: {}
});