import request from "@/utils/request";

export function createOrGetUser(userData) {
    console.log(userData)
    return request({
        url: "api/user/get-or-create",
        method: "post",
        data: {
            "fbuid": userData["fbuid"],
            "username": userData["username"],
            "userPic": userData["userPic"]
        }
    })
}

export function vote(fbuid, videoId) {
    return request({
        url: "api/videos/vote",
        method: "post",
        data: {
            fbuid,
            videoId
        }
    })
}

export function getVideos(queries) {
    let queryString = ''
    if(typeof(queries)==='object'){
        for (const [key, value] of Object.entries(queries)) {
            if(value){
                queryString += `${key}=${value}&`
            }
        }
        // 去掉最後面的 &
        queryString = queryString.length > 0 ? queryString.slice(0,-1) : queryString
    }

    // console.log('queryString: ' + queryString)


    return request({
        url: "api/videos?"+queryString,
        method: "get"
    })
}