<template>
  <router-view />
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  mounted() {
    this.updateVideos({
      limit: 50,
    });
  },
  methods: {
    ...mapActions(["updateVideos"]),
  },
};
</script>

<style lang="scss"></style>
