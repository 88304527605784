import axios from "axios";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
  // timeout: 5000
});

service.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  ({ status, data }) => {
    if (status === 200) {
      return data;
    }
    return Promise.reject(data.message);
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default service;
